<template>
  <router-link
    :to="{ name: 'challengeDetail', params: { id: challenge.id } }"
  >
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body  text-center">
        <div class="py-1" style="max-height: 230px; min-height: 230px; overflow: hidden; position: relative;">
          
          <img
            :src="challenge.icon ? challenge.icon.uri  : '/assets/media/bipart/idea-default.svg'"
            class="align-self-center w-100"
            style="position: absolute; margin: auto; top: 0;left: 0;right: 0;" 
            alt="Avatar"
          />
          
        </div>
        <div class="mt-3 text-dark font-weight-bolder text-hover-primary font-size-h5 text-left">
            {{ challenge.name }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ChallengeCard",
  props: ["challenge"]
};
</script>

<style scoped></style>
